<template>
  <div class="contents">
    <div class="contents-one">
      <van-cell
        :value="wxLoginInfo.nickname"
        is-link
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/set_name.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span
            class="custom-title"
            style=" vertical-align: middle;"
          >昵称</span>
        </template>
      </van-cell>
    </div>
    <div class="contents-one">
      <van-cell
        value
        is-link
        @click="jumpLogin"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/set_user.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span
            class="custom-title"
            style=" vertical-align: middle;"
          >绑定用户</span>
        </template>
      </van-cell>
    </div>
    <div class="contents-one">
      <van-cell
        value
        is-link
        @click="jumpNoticeIndex"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/set_cement.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span
            class="custom-title"
            style=" vertical-align: middle;"
          >最新公告</span>
        </template>
      </van-cell>
    </div>
    <!-- <div class="contents-one" v-if="!isCbry">
      <van-cell
        v-if="currUserData.administratorFlag!==1 && currUserData.adminFlag!==1"
        :value="currUserData.yyt_name | yytFilter"
        is-link
      >
        使用 title 插槽来自定义标题
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/set_name.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span class="custom-title" style=" vertical-align: middle;">所属营业厅</span>
        </template>
      </van-cell>
      <van-cell v-else :value="currUserData.tenant_name" is-link>
        使用 title 插槽来自定义标题
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/set_name.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span class="custom-title" style=" vertical-align: middle;">租户</span>
        </template>
      </van-cell>
    </div> -->
    <!-- <div class="contents-one">
      <van-cell value="0" is-link @click="jumpPointsCenter">
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/set_Integral.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span class="custom-title" style=" vertical-align: middle;">我的积分</span>
        </template>
      </van-cell>
    </div>
    <div class="contents-one">
      <van-cell value is-link @click="jumpShareIndex">
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/ma.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span class="custom-title" style=" vertical-align: middle;">邀请有礼</span>
        </template>
      </van-cell>
    </div>
    <div class="contents-one">
      <van-cell value="立即签到" is-link @click="jumpPointsCenter">
        <template #title>
          <img
            src="../../../../assets/PersonalCenter/Calend.png"
            alt
            width="30px"
            style=" vertical-align: middle;"
          />
          <span class="custom-title" style=" vertical-align: middle;">每日签到</span>
        </template>
      </van-cell>
    </div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@/config'
export default {
  data () {
    return {}
  },
  filters: {

  },
  computed: {
    ...mapGetters(['wxLoginInfo', 'bindList']),
    currUserData () {
      let data = {}
      this.bindList.forEach((item) => {
        if (item.flag) {
          data = item
        }
      })
      return data
    },
  },
  methods: {
    jumpLogin () {
      this.$router.push('/BandingAccount')
    },
    jumpNoticeIndex () {
      this.$router.push('/noticeIndex')
    },
    jumpPointsCenter () {
      this.$router.push('/pointsCenter')
    },
    jumpShareIndex () {
      this.$router.push('/shareIndex')
    },
  },
}
</script>
 
<style lang = "less" scoped>
.contents-one {
  img {
    padding-right: 0.3125rem /* 5/16 */;
  }
  .van-cell__title {
    flex: none;
    display: inline-block;
  }
}
</style>