<template>
  <div class="contents top-padding">
    <top-home
      title="个人中心"
      :className="['top-white']"
    ></top-home>
    <div class="personalCenter">
      <div class="head">
        <div class="images">
          <img
            :src="wxLoginInfo.headImgUrl"
            alt
            width="100%"
          />
        </div>
        <div class="title">{{wxLoginInfo.nickname}}</div>
        <top-home path="/home"></top-home>
      </div>
      <div
        class="listOf"
        style="margin-top:5px;"
      >
        <div class="mui-table-view">
          <PercenterChild></PercenterChild>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PercenterChild from './PercenterChild/PercenterChild.vue'
import config from '@/config'
export default {
  components: {
    PercenterChild,
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['wxLoginInfo']),
  },
}
</script>
 
<style lang="less" scoped>
.personalCenter {
  position: fixed;
  height: 100vh;
  background-color: rgb(245, 245, 245);
  width: 100%;
}
.head {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 150px;
  background: url('../../../assets/PersonalCenter/personalCenterImg.jpg')
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding-bottom: 20px;
  .images {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    border: 2px solid #fff;
    margin-top: 30px;
  }
  .title {
    font-size: 16px;
    color: #fff;
    padding-top: 10px;
  }
}
.mui-table-view {
  margin: 0 10px;
  background-color: #fff;
}
.listOf {
  border-radius: 10px;
}
</style>